<template>
  <div>
    <!--    <commingSoon />-->
    <b-container>
      <b-card class="card-about">
        <h2
          class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5"
        >
          {{ $t("endUser.privacyPolicy") }}
        </h2>
        <p
          class="text-justify font-size-20 text-black px-4"
          v-html="privacyPolicy"
        ></p>
      </b-card>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "privacyPolicy",
  mounted() {
    core.index();
  },
  computed: {
    privacyPolicy() {
      return this.$store.getters["Setting/appSettings"]["privacy_policy"];
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.card-about {
  margin: 3rem 0 !important;
  padding: 3rem !important;
  text-align: center;
}
</style>
